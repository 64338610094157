//分销员会员提成报表
import request from '@/utils/request'

//列表
export function distributionVipCommission (params) {
    return request({
      url: '/api/system/vip/distribution/reportForms/distributionVipCommission',
      method: 'get',
      params
    })
}
//合计
export function distributionVipCommissionSummary (params) {
  return request({
    url: '/api/system/vip/distribution/reportForms/distributionVipCommissionSummary',
    method: 'get',
    params
  })
}