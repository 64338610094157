import request from '@/utils/request'

// 分销员列表
export function listDistributionMan (query) {
  return request({
    url: '/api/system/vip/distribution/man/list',
    method: 'get',
    params: query
  })
}

//获取分销员详情
export function getDistributionManDetail (vipId) {
  return request({
    url: '/api/system/vip/distribution/man/getDetail',
    method: 'get',
    params: { vipId }
  })
}

// 添加分销员
export function addDistributionMan (data) {
  return request({
    url: '/api/system/vip/distribution/man/save',
    method: 'post',
    data: data
  })
}

// 修改分销员
export function updateDistributionMan (data) {
  return request({
    url: '/api/system/vip/distribution/man/update',
    method: 'put',
    data: data
  })
}

// 删除分销员
export function delDistributionMan (vipIds) {
  return request({
    url: '/api/system/vip/distribution/man/remove',
    method: 'delete',
    data: vipIds
  })
}

// 分销员  结算
export function DistributionManSettlementAPI (data) {
    return request({
      url: '/api/system/vip/distribution/man/settlement',
      method: 'post',
      data
    })
}

// 还原分销员
export function restoreDistributionMan (data) {
    return request({
      url: '/api/system/vip/distribution/man/restoreDistributionMan',
      method: 'post',
      data
    })
}
  

// 会员级别列表
export function listlevel(query) {
  return request({
    url: "/api/system/vip/distribution/level/list",
    method: "get",
    params: query,
  });
}

//获取分销级别详情
export function getlevelDetail(distributionLevelId) {
  return request({
    url: "/api/system/vip/distribution/level/getDetail",
    method: "get",
    params: { distributionLevelId },
  });
}

// 添加分销级别
export function addlevel(data) {
  return request({
    url: "/api/system/vip/distribution/level/save",
    method: "post",
    data: data,
  });
}

// 修改分销级别
export function updatelevel(data) {
  return request({
    url: "/api/system/vip/distribution/level/update",
    method: "put",
    data: data,
  });
}

// 删除分销级别
export function dellevel(vipLevelIds) {
  return request({
    url: "/api/system/vip/distribution/level/remove",
    method: "delete",
    data: vipLevelIds,
  });
}

//分销员审核
export function updateBillStatus(distributionManVipIds, billStatus) {
  return request({
    url:'/api/system/vip/distribution/man/audit',
    method: 'put',
    data: {
      distributionManVipIds,
      billStatus
    }
  })
}

// 分销员的合计
export function listDistributionManSummary (params) {
  return request({
    url: '/api/system/vip/distribution/man/listSummary',
    method: 'get',
    params
  })
}

//分销等级接口
export function restoreLevel(data) {
  return request({
    url: "/api/system/vip/distribution/level/restore",
    method: "post",
    data,
  });
}