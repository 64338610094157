//分销员结算 
import request from '@/utils/request'

//列表
export function distributionSettlement (params) {
    return request({
      url: '/api/system/vip/distribution/reportForms/distributionSettlement',
      method: 'get',
      params
    })
}
//合计
export function distributionSettlementSummary (params) {
  return request({
    url: '/api/system/vip/distribution/reportForms/distributionSettlementSummary',
    method: 'get',
    params
  })
}